<template>
    <div class="hs-popup fixed top-0 bottom-0 left-0 right-0 w-full h-full flex items-center" v-if="isOpen">
        <div class="bg-white w-md left-0 right-0 w-full max-w-sm m-auto text-center relative">
            <div class="flex items-center justify-center h-8 w-8 bg-white rounded-sm absolute top-2 right-2 cursor-pointer" @click="setIsOpen(false)">
                <svg class="h-5 w-5 text-black"><use xlink:href="#icon--x"></use></svg>
            </div>

            <div class="px-2">
                <img :src="`/images/summer/${$i18n.locale}/buddy_splashscreen.svg`" class="w-24 m-auto mb-4 mt-12" />
                <h2><strong class="font-sans-extrabold">Buddy</strong> {{ $t('homescreen_popup.speichern') }}</h2>
                <p>{{ $t('homescreen_popup.text') }}</p>
            </div>

            <div class="bg-gray-100 py-4 mt-4 flex justify-center">
                <span>{{ $t('homescreen_popup.klicke_auf') }}</span>
                <svg class="h-5 w-5 ml-2 mr-2 text-sw-blue"><use xlink:href="#icon--to-homescreen"></use></svg>
                <span>{{ $t('homescreen_popup.zum_home_bildschirm') }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TheHomescreenPopup',
    data() {
        return {
            isClicked: false,
            isOpen: false,
        }
    },
    methods: {
        setIsOpen: function (value) {
            this.isOpen = value
        },
        checkCookiebanner: function (e) {
            if (e.key && e.key === 'gtm_accepted') {
                localStorage.setItem('hs_popup_shown', false)
            }
        },
        showPopup() {
            if ((window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone) || document.referrer.includes('android-app://')) {
                // is PWA
            } else {
                this.setIsOpen(true)
            }

            localStorage.setItem('hs_popup_shown', true)
        },
        onScroll: function () {
            if (window.scrollY >= document.body.scrollHeight / 2) {
                if (localStorage.getItem('hs_popup_shown') === 'false' || localStorage.getItem('hs_popup_shown') === null) {
                    this.showPopup()
                }
            }
        },
    },
    mounted() {
        if (localStorage.getItem('hs_popup_shown') === 'false') {
            this.showPopup()
        }
    },
    created() {
        window.addEventListener('storage', this.checkCookiebanner)
        window.addEventListener('scroll', this.onScroll)
    },
    destroyed() {
        window.removeEventListener('storage')
        window.removeEventListener('scroll')
    },
}
</script>
