<template>

  <div class="sw_intro" v-if="!isClicked">

    <div class="fixed z-50 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="flex justify-center full-h-screen h-full w-full text-center">
        <div class="fixed inset-0 bg-sw-blue-dark transition-opacity" aria-hidden="true"></div>

        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <div class="w-full h-full text-left transform transition-all pb-4 sm:align-middle">
          <div class="absolute top-0 right-0 pt-4 xs:pt-8 pr-4 z-10">
            <button v-on:click="closeModal" type="button" class="text-white hover:opacity-50 focus:outline-none">
              <span class="sr-only">Close</span>
              <svg class="h-6 w-6">
                <use xlink:href="#icon--x"></use>
              </svg>
            </button>
          </div>
          <swiper :slides-per-view="1"
                  :space-between="0"
                  :pagination="{ clickable: true, el: '.sw_swiper-pagination' }"
                  :navigation="{ nextEl: '.sw_swiper-button-next' }"
                  class="relative pb-10 h-full w-full text-white text-center">

            <div class="px-4 w-full flex justify-between">
              <div class="static swiper-pagination sw_swiper-pagination" slot="pagination"></div>
              <span v-on:click="closeModal">Überspringen</span>
            </div>

            <swiper-slide class="h-full w-full pt-8 pb-4 px-4 xs:px-8 overflow-y-scroll">
              <div class="max-w-3xl mx-auto sm:py-8 flex flex-col items-center">

                <img class="w-40 h-40 sm:w-56 sm:h-56" :src="`/images/summer/${$i18n.locale}/splash_bilder_01.svg`">

                <div v-html="page1" v-if="page1"></div>

              </div>

            </swiper-slide>

            <swiper-slide class="h-full w-full pt-8 pb-4 px-4 xs:px-8 overflow-y-scroll">
              <div class="max-w-3xl mx-auto sm:py-8 flex flex-col items-center">

                <img class="w-40 h-40 sm:w-56 sm:h-56" :src="`/images/summer/${$i18n.locale}/splash_bilder_01.svg`">
                <div v-html="page2" v-if="page2"></div>

                <div class="space-y-2.5 sm:px-6 w-full mt-6">
                    <div class="bg-white bg-opacity-20 px-8 py-2 rounded-2xl text-left flex items-center">
                        <svg class="w-12 h-8 mr-6 text-sw-orange">
                            <use :xlink:href="'#icon--bike'"></use>
                        </svg>
                        <p class="mb-0"><span class="font-sans-bold">Bike</span> Buddy</p>
                    </div>
                    <div class="bg-white bg-opacity-20 px-8 py-2 rounded-2xl text-left flex items-center">
                        <svg class="w-12 h-8 mr-6 text-sw-green">
                            <use :xlink:href="'#icon--hiking'"></use>
                        </svg>
                        <p class="mb-0"><span class="font-sans-bold">{{ $t("intro.wander") }}</span> Buddy</p>
                    </div>
                    <div class="bg-white bg-opacity-20 px-8 py-2 rounded-2xl text-left flex items-center">
                      <svg class="w-12 h-8 mr-6 text-sw-blue">
                        <use :xlink:href="'#icon--snowsports'"></use>
                      </svg>
                      <p class="mb-0"><span class="font-sans-bold">{{ $t("intro.skisnowboard") }}</span> Buddy</p>
                    </div>

                    <div class="bg-white bg-opacity-20 px-8 py-2 rounded-2xl text-left flex items-center">
                      <svg class="w-12 h-8 mr-6 text-sw-violet">
                        <use :xlink:href="'#icon--cross-country-skiing'"></use>
                      </svg>
                      <p class="mb-0"><span class="font-sans-bold">{{ $t("intro.langlauf") }}</span> Buddy</p>
                    </div>
                </div>

              </div>
            </swiper-slide>

            <swiper-slide class="h-full w-full pt-8 pb-4 px-4 xs:px-8 overflow-y-scroll">
              <div class="max-w-3xl mx-auto sm:py-8 flex flex-col items-center">

                <img class="w-40 h-40 sm:w-56 sm:h-56" :src="`/images/summer/${$i18n.locale}/splash_bilder_01.svg`">
                <div class="mt-8">
                  <div v-html="page3" v-if="page3"></div>
                </div>
                <Link v-on:click="closeModal" :style="1" :text="$t('intro.letsgo')" :link="at" :class="'w-full mt-4'" :linkClass="'text-sw-blue-dark w-full md:w-auto'"/>

              </div>
            </swiper-slide>

          </swiper>
        </div>
      </div>
    </div>
  </div>

</template>

<style>
.full-h-screen {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}
</style>

<script>

import axios from "axios";
import 'swiper/swiper-bundle.css';
import { Swiper, SwiperSlide } from 'swiper/vue';
import Link from "@/components/contents/partials/Link";
import SwiperCore, {
  Navigation,
  Pagination
} from 'swiper';

SwiperCore.use([Navigation, Pagination]);

export default {
  name: 'Intro',
  data() {
    return {
      isClicked: false,
      page1: null,
      page2: null,
      page3: null
    }
  },
  methods: {
    closeModal: function() {
      this.isClicked = !this.isClicked;
      this.isClicked ? document.body.classList.remove("overflow-hidden") : document.body.classList.add("overflow-hidden");
      localStorage.setItem('introClosed', true);
    },
  },
  components: {
    Link,
    Swiper,
    SwiperSlide
  },
  mounted() {
    var element = document.querySelector('.full-h-screen');
    let vh = window.innerHeight * 0.01;
    element.style.setProperty('--vh', `${vh}px`);

    window.addEventListener('resize', () => {
      let vh = window.innerHeight * 0.01;
      element.style.setProperty('--vh', `${vh}px`);
    });

    // if(navigator.platform == 'iPad' || navigator.platform == 'iPhone' || navigator.platform == 'iPod'){
    //   this.$refs.buttons.style.removeProperty("bottom")
    //   this.$refs.buttons.style.top = (window.innerHeight -  this.$refs.buttons.offsetHeight) + "px"
    // }

    let contentUrl = process.env.VUE_APP_API_URL+"spashscreen"

    if (this.$i18n.locale === "en") {
      contentUrl = process.env.VUE_APP_API_URL+"en/spashscreen"
    }

    axios.get(contentUrl).then(response => {
      this.page1 = response.data.content.colPos0[0].content.bodytext
      this.page2 = response.data.content.colPos0[1].content.bodytext
      this.page3 = response.data.content.colPos0[2].content.bodytext
    })
  }
}
</script>
